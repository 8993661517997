@charset "UTF-8";
@font-face {
  font-family: "DotGothic";
  src: url(DotGothic-Regular.e3fdda2f.ttf) format("truetype");
  /* все современные браузеры */
}
*, *:before, *:after {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}
html {
  font-size: 16px;
}
@media (max-width: 1023px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  html {
    font-size: 12px;
  }
}
body {
  position: relative;
  margin: 0;
  color: #1D1D1D;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0.1em;
  font-family: "DotGothic", Helvetica, Arial, sans-serif;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-focus-ring-color: rgba(255, 255, 255, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}
ul, ol {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
address {
  font-style: normal;
}
img,
video,
iframe {
  max-width: 100%;
}
img {
  border: 0;
  vertical-align: top;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
p {
  margin: 0;
}
a {
  color: inherit;
  text-decoration: none;
}
input,
textarea {
  width: 100%;
  padding: 0;
  border: none;
  font-family: "DotGothic", Helvetica, Arial, sans-serif;
  background-color: transparent;
}
textarea {
  height: 110px;
  resize: none;
}
button {
  font-family: "DotGothic", Helvetica, Arial, sans-serif;
  text-align: center;
  border: none;
  background: none;
  cursor: pointer;
}
.wrapper {
  padding: 0 30px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .wrapper {
    padding: 0 15px;
  }
}
.section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 11vw 0;
}
@media (max-width: 480px) {
  .section {
    margin: 25vw 0;
  }
}
.section.--wide {
  margin: 0 -30px;
}
@media (max-width: 767px) {
  .section.--wide {
    margin: 0 -15px;
  }
}
.title {
  margin-bottom: 25px;
  font-size: 5.5625rem;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .title {
    font-size: 5rem;
  }
}
@media (max-width: 480px) {
  .title {
    font-size: 4rem;
  }
}
.title.--section {
  font-size: 2.375rem;
  line-height: 1.5;
  text-align: center;
}
.title.--block {
  font-size: 1.875rem;
}
.title.--extra {
  font-size: 1.5rem;
}
.title.--subtitle {
  font-size: 1.375rem;
}
.text {
  font-size: 0.875rem;
  line-height: 1.5;
}
.text.--lead {
  font-size: 1.125rem;
}
.text.--head {
  font-size: 1.25rem;
}
.text.--date {
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.2;
}
.text.--normal {
  font-weight: 400;
}
.button {
  display: inline-block;
  min-width: 236px;
  padding: 16px;
  color: #FFF;
  font-size: 1.125rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  border-radius: 4px;
  background-color: #1D1D1D;
  transition: all 0.25s;
}
@media (max-width: 480px) {
  .button {
    min-width: 200px;
  }
}
.button:hover {
  box-shadow: 0px 10px 40px 0px rgba(63, 126, 202, 0.25);
}
.list > * {
  display: flex;
  align-items: center;
}
.list > *:before {
  content: url("dot.1733cc6e.svg");
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 14px;
}
.accent {
  padding: 2px 5px;
  background-color: #FFE07D;
}
.none {
  display: none;
}
.link {
  display: inline;
  padding-bottom: 1px;
  color: #4B80C3;
  line-height: 1.3;
  border-color: unset;
  background-image: linear-gradient(currentColor, currentColor);
  background-size: 0% 2px;
  background-repeat: no-repeat;
  background-position: left bottom;
  transition: all 0.25s;
  cursor: pointer;
}
.link:hover {
  background-size: 100% 2px;
}
.spot,
.square {
  position: absolute;
  max-width: 25vw;
}
@media (max-width: 1023px) {
  .square {
    opacity: 0.15;
  }
}
.socials {
  display: flex;
  align-items: center;
}
@media (max-width: 480px) {
  .socials {
    justify-content: center;
  }
}
.socials__item:not(:last-of-type) {
  margin-right: 25px;
}
.socials__icon {
  width: 24px;
}
.socials__icon.--discord {
  width: 32px;
}
.hero {
  position: relative;
  margin-top: 0;
  padding-top: 2vw;
}
@media (max-width: 1024px) {
  .hero {
    padding-top: 8vw;
  }
}
.hero__bg {
  position: absolute;
  top: 50px;
  width: 90vw;
  max-width: 1300px;
  z-index: 1;
}
.hero__bg-image {
  width: 100%;
}
.hero__content {
  position: relative;
  text-align: center;
  z-index: 3;
}
.hero__decoration {
  position: absolute;
}
.hero__decoration:first-child {
  top: -15%;
  left: -20%;
  transform: translateX(-100%);
}
@media (max-width: 1024px) {
  .hero__decoration:first-child {
    left: -5%;
  }
}
.hero__decoration:nth-child(2) {
  top: -11%;
  right: -30%;
  transform: translateX(100%);
}
@media (max-width: 1024px) {
  .hero__decoration:nth-child(2) {
    right: -11%;
  }
}
.hero__decoration:nth-child(3) {
  top: 78%;
  left: 5%;
  transform: translateX(-100%);
}
@media (max-width: 1024px) {
  .hero__decoration:nth-child(3) {
    top: 78%;
    left: 5%;
  }
}
.hero__decoration:nth-child(4) {
  top: 57%;
  right: -18%;
  transform: translateX(100%);
}
@media (max-width: 1024px) {
  .hero__decoration:nth-child(4) {
    right: 0;
  }
}
.hero__decoration:nth-child(5) {
  top: 125%;
  left: -25%;
  transform: translateX(-100%);
}
@media (max-width: 1024px) {
  .hero__decoration:nth-child(5) {
    left: -5%;
  }
}
.hero__decoration:nth-child(6) {
  top: 109%;
  right: -22%;
  transform: translateX(100%);
}
@media (max-width: 1024px) {
  .hero__decoration:nth-child(6) {
    right: -4%;
  }
}
.hero__logos {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 4.5vw;
  z-index: 1;
}
@media (max-width: 1024px) {
  .hero__logos {
    margin-bottom: 8vw;
  }
}
.hero__logo {
  max-width: 15vw;
}
.hero__logo.--upcoming {
  width: 125px;
}
.hero__logo.--calendar {
  width: 130px;
}
.hero__logo.--r {
  width: 121px;
}
.hero__logo.--non-fungi {
  width: 155px;
}
.hero__logo:first-child, .hero__logo:nth-child(3) {
  margin-right: 1.5vw;
}
.hero__logo:nth-child(2) {
  margin-right: 3vw;
}
.hero__logo-image {
  border-radius: 4px;
}
.hero__title span {
  position: relative;
}
.hero__title span img {
  position: absolute;
  top: 0;
  right: 0;
  width: 55%;
  transform: translate(28%, -30%);
}
.hero__info {
  max-width: 500px;
  margin: 0 auto;
}
@media (max-width: 1023px) {
  .hero__info {
    max-width: 450px;
  }
}
@media (max-width: 767px) {
  .hero__info {
    max-width: 390px;
  }
}
@media (max-width: 480px) {
  .hero__info {
    max-width: 350px;
  }
}
.hero__subtitle {
  margin-bottom: 12px;
}
.hero__extra {
  margin-top: 25px;
}
.hero__extra-item {
  margin: 0;
}
.hero__extra-item + .hero__extra-item {
  margin-top: 12px;
}
.hero__button {
  position: relative;
  margin-top: 30px;
}
.hero__button-cloud {
  position: absolute;
  top: 0;
  left: 0;
  width: 22.4%;
  transform: translate(-18%, -40%);
}
.hero__button-squares {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.hero__button-squares.--left {
  right: calc(100% + 23px);
}
@media (max-width: 767px) {
  .hero__button-squares.--left {
    opacity: 0.15;
    right: 90%;
  }
}
.hero__button-squares.--right {
  left: calc(100% + 23px);
}
@media (max-width: 767px) {
  .hero__button-squares.--right {
    opacity: 0.15;
    left: 90%;
  }
}
.hero__socials {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.hero__picture {
  position: relative;
  display: block;
  max-width: 70vw;
  margin-top: 30px;
  z-index: 2;
}
.lottery__spot {
  top: -8%;
  right: 0%;
  transform: translateX(80%);
}
.lottery__square {
  opacity: 0.1;
}
.lottery__square:first-child {
  top: 40%;
  left: 10%;
}
@media (max-width: 767px) {
  .lottery__square:first-child {
    top: 52%;
    left: 17%;
  }
}
.lottery__square:nth-child(2) {
  right: 8%;
  bottom: 1%;
}
@media (max-width: 767px) {
  .lottery__square:nth-child(2) {
    right: 2%;
    bottom: -4%;
  }
}
.lottery__info {
  position: relative;
  display: flex;
  padding: 33px 65px;
  border-radius: 22px;
  border: 3px solid #394F44;
}
@media (max-width: 1023px) {
  .lottery__info {
    display: block;
    margin-top: 20px;
    padding: 30px 35px;
  }
}
@media (max-width: 767px) {
  .lottery__info {
    display: block;
    padding: 20px 25px;
  }
}
.lottery__info-hat {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(20px, -44px);
}
.lottery__info-cloud {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(-13px, 17px);
}
.lottery__list {
  max-width: 900px;
}
.lottery__list:not(:last-of-type) {
  margin-right: 40px;
}
@media (max-width: 1023px) {
  .lottery__list:not(:last-of-type) {
    margin-bottom: 20px;
  }
}
.lottery__item:not(:last-of-type) {
  margin-bottom: 20px;
}
.lottery__bg {
  position: relative;
  margin-top: 100px;
}
@media (max-width: 767px) {
  .lottery__bg {
    margin-top: 60px;
  }
}
.lottery__bg-decoration {
  position: absolute;
  top: 0;
  max-width: 40vw;
}
.lottery__bg-decoration:first-child {
  left: 0;
  transform: translateX(-45%);
}
.lottery__bg-decoration:nth-child(2) {
  right: 0;
  transform: translateX(35%);
}
.lottery__bg-decoration-block {
  position: relative;
}
.lottery__bg-decoration-cloud {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate(-5%, 55%);
}
.lottery__bg-circle {
  position: relative;
  width: 500px;
  max-width: 65vw;
  border-radius: 50%;
  border: 14px solid #FAFAFA;
  background-color: #FFF;
  z-index: 1;
}
@media (max-width: 650px) {
  .lottery__bg-circle {
    border-width: 8px;
  }
}
.lottery__bg-circle-ghost {
  opacity: 0;
}
.lottery__bg-circle-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: gif 2.5s linear infinite;
  background-image: url("frame-1.71f2608a.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
@keyframes gif {
  24% {
    background-image: url("frame-1.71f2608a.png");
  }
  25% {
    background-image: url("frame-2.b05bbecd.png");
  }
  49% {
    background-image: url("frame-2.b05bbecd.png");
  }
  50% {
    background-image: url("frame-3.ca6f796e.png");
  }
  74% {
    background-image: url("frame-3.ca6f796e.png");
  }
  75% {
    background-image: url("frame-4.42b9e476.png");
  }
  99% {
    background-image: url("frame-4.42b9e476.png");
  }
  100% {
    background-image: url("frame-1.71f2608a.png");
  }
}
.collection__spot:first-of-type {
  top: 40%;
  left: 0%;
  transform: rotate(110deg) translate(-50%, 85%);
}
.collection__spot:nth-of-type(2) {
  right: 0%;
  bottom: -25%;
  transform: rotate(75deg) translate(25%, -70%);
}
.collection__description {
  max-width: 50vw;
  text-align: center;
}
@media (max-width: 1023px) {
  .collection__description {
    max-width: 70vw;
  }
}
.collection__list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 110px;
  width: 50vw;
  margin-bottom: -80px;
}
@media (max-width: 1023px) {
  .collection__list {
    width: 90vw;
    margin-top: 40px;
    margin-bottom: -40px;
  }
}
.collection__item {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 33.3333333333%;
  margin-bottom: 80px;
}
@media (max-width: 1023px) {
  .collection__item {
    margin-bottom: 40px;
  }
}
@media (max-width: 550px) {
  .collection__item {
    width: 50%;
  }
}
.collection__item:first-of-type .collection__item-square {
  left: 0;
  bottom: calc(100% + 6px);
}
@media (max-width: 767px) {
  .collection__item:first-of-type .collection__item-square {
    top: -42px;
    left: -44px;
    bottom: unset;
  }
}
.collection__item:nth-of-type(2) .collection__item-square {
  top: -41px;
  right: -49px;
}
.collection__item:nth-of-type(3) .collection__item-square {
  top: 18px;
  left: calc(100% + 10px);
}
@media (max-width: 767px) {
  .collection__item:nth-of-type(3) .collection__item-square {
    top: -42px;
  }
}
.collection__item:nth-of-type(4) .collection__item-square {
  top: 30px;
  right: calc(100% + 17px);
}
@media (max-width: 767px) {
  .collection__item:nth-of-type(4) .collection__item-square {
    top: unset;
    right: -45px;
    bottom: -45px;
  }
}
.collection__item:nth-of-type(5) .collection__item-square {
  left: calc(100% + 12px);
  bottom: 5px;
}
.collection__item-picture {
  position: relative;
}
.collection__item-image {
  position: relative;
  box-shadow: 0px 10px 40px 0px rgba(63, 126, 202, 0.25);
  z-index: 1;
}
.collection__item-name {
  margin-top: 18px;
  text-transform: uppercase;
}
.collection__item:nth-child(4) {
  margin-left: auto;
}
@media (max-width: 550px) {
  .collection__item:nth-child(4) {
    margin-left: unset;
  }
}
.collection__item:nth-child(5) {
  margin-right: auto;
}
@media (max-width: 550px) {
  .collection__item:nth-child(5) {
    margin-left: auto;
    margin-right: auto;
  }
}
.member {
  background: url("section-pattern.3cc7b190.svg") 50% 50%/cover no-repeat #E5E5E5;
  overflow: hidden;
}
.member__square:first-of-type {
  top: 13%;
  left: 11%;
}
@media (max-width: 767px) {
  .member__square:first-of-type {
    left: 0;
  }
}
.member__square:nth-of-type(2) {
  right: 6%;
  bottom: 6%;
}
.member__content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12vw 6vw;
}
@media (max-width: 650px) {
  .member__content {
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .member__content {
    padding: 15vw 6vw;
  }
}
.member__info {
  max-width: 570px;
  margin-right: 8vw;
}
@media (max-width: 650px) {
  .member__info {
    max-width: 75vw;
    margin-right: unset;
    margin-bottom: 40px;
  }
}
.member__title {
  position: relative;
  margin-bottom: 12px;
}
.member__title.title.--section {
  text-align: left;
}
.member__title-decoration {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(25%, -50%);
}
.member__description {
  text-transform: uppercase;
}
.member__picture {
  position: relative;
  max-width: 30vw;
}
@media (max-width: 650px) {
  .member__picture {
    max-width: 50vw;
  }
}
.member__picture-first {
  position: relative;
  z-index: 1;
}
.member__picture-second, .member__picture-third {
  position: absolute;
  left: 50%;
  bottom: 0;
}
.member__picture-second {
  width: 90%;
  opacity: 0.6;
  transform: translate(-50%, 12px);
}
.member__picture-third {
  width: 80%;
  opacity: 0.3;
  transform: translate(-50%, 24px);
}
.member__picture-cloud {
  position: absolute;
  top: 0;
  right: 0;
  width: 141px;
  max-width: 31%;
  transform: translate(24%, -27%);
  z-index: 2;
}
.member__hero {
  position: absolute;
  max-height: 12vw;
}
@media (max-width: 1023px) {
  .member__hero {
    display: none;
  }
}
.member__hero.--opacity {
  opacity: 0.15;
}
.member__hero.--icons {
  max-height: 7vw;
}
.member__hero:first-child {
  left: 40vw;
  bottom: 0;
}
.member__hero:nth-child(2) {
  left: 1vw;
  bottom: 0;
  max-width: 16vw;
}
.member__hero:nth-child(3) {
  top: 0;
  left: 15vw;
}
.member__hero:nth-child(4) {
  top: 0;
  right: 10vw;
}
@media (max-width: 1600px) {
  .member__hero:nth-child(4) {
    right: 1vw;
  }
}
.member__hero:nth-child(5) {
  left: 20vw;
  bottom: 0;
}
.member__hero:nth-child(6) {
  top: 1vw;
  right: 35vw;
}
.roadmap__spot:first-of-type {
  top: 0%;
  left: 0%;
  transform: rotate(50deg) translate(-50%, 40%);
}
.roadmap__spot:nth-of-type(2) {
  right: 0%;
  bottom: -5%;
  transform: rotate(75deg) translate(25%, -70%);
}
.roadmap__steps {
  max-width: 80vw;
  margin-top: 60px;
}
@media (max-width: 767px) {
  .roadmap__steps {
    margin-top: 20px;
  }
}
.roadmap__step {
  max-width: 930px;
  text-transform: uppercase;
}
.roadmap__step.--active .roadmap__step-head:after {
  border-color: #C80A0A;
}
.roadmap__step.--active .roadmap__step-name {
  position: relative;
  display: flex;
  align-items: center;
}
.roadmap__step.--active .roadmap__step-name:after {
  content: "Right NOW";
  display: block;
  margin-left: 14px;
  padding: 7px 12px 5px;
  color: #009A5A;
  font-size: 0.875rem;
  text-transform: uppercase;
  white-space: nowrap;
  line-height: 1.5;
  border-radius: 12px;
  background-color: rgba(0, 154, 90, 0.1);
}
.roadmap__step + .roadmap__step {
  margin-top: 16px;
}
.roadmap__step-block {
  position: relative;
  padding-left: 78px;
}
@media (max-width: 480px) {
  .roadmap__step-block {
    padding-left: 50px;
  }
}
.roadmap__step-head {
  margin-bottom: 16px;
}
.roadmap__step-head:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
  display: block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 6px solid #1D1D1D;
}
.roadmap__step-body {
  padding-bottom: 30px;
}
.roadmap__step-body:after {
  content: "";
  position: absolute;
  top: 0;
  left: 13px;
  bottom: 0;
  display: block;
  width: 2px;
  background-color: #ECECEC;
}
.roadmap__step-date {
  margin-bottom: 16px;
}
.team {
  display: flex;
  align-items: center;
  justify-content: center;
}
.team__spot {
  bottom: -40%;
  left: 0%;
  transform: rotate(110deg) translate(-50%, 85%);
}
.team__content {
  display: flex;
  align-items: center;
  margin-top: 60px;
}
@media (max-width: 767px) {
  .team__content {
    flex-direction: column-reverse;
    margin-top: 20px;
  }
}
.team__info {
  width: 475px;
  max-width: 35vw;
  margin-right: 12vw;
  text-transform: uppercase;
}
@media (max-width: 1023px) {
  .team__info {
    margin-right: 6vw;
  }
}
@media (max-width: 767px) {
  .team__info {
    max-width: 80vw;
  }
}
.team__socials {
  margin-top: 25px;
}
.team__picture {
  position: relative;
  display: flex;
  justify-content: center;
  width: 348px;
  max-width: 30vw;
}
@media (max-width: 767px) {
  .team__picture {
    max-width: 50vw;
    margin-bottom: 40px;
  }
}
.team__picture-square:first-of-type {
  right: calc(100% + 30px);
  bottom: 0;
}
.team__picture-square:nth-of-type(2) {
  top: 0;
  left: calc(100% + 30px);
}
.team__picture-lines {
  position: absolute;
  max-width: 60vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
@media (max-width: 1023px) {
  .team__picture-lines {
    opacity: 0.2;
  }
}
@media (max-width: 767px) {
  .team__picture-lines {
    max-width: 95vw;
  }
}
.team__picture-block {
  position: relative;
  border-radius: 145px;
  background-color: #F3F3F3;
  box-shadow: 0px 15px 15px -10px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  z-index: 2;
}
.team__hey {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}
.team__hey-icon {
  margin-left: 10px;
}
.team__description:not(:first-child) {
  margin-top: 18px;
}
.team__list {
  margin-top: 15px;
}
.team__list-item + .team__list-item {
  margin-top: 14px;
}
.team__members {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}
@media (max-width: 1023px) {
  .team__members {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .team__members {
    margin-top: 40px;
  }
}
.team__member {
  display: flex;
  align-items: center;
  width: 520px;
  max-width: 45vw;
}
@media (max-width: 1023px) {
  .team__member {
    max-width: 80vw;
  }
  .team__member + .team__member {
    margin-top: 50px;
  }
}
.team__member:not(:last-of-type) {
  margin-right: 50px;
}
@media (max-width: 1023px) {
  .team__member:not(:last-of-type) {
    margin-right: unset;
  }
}
.team__member-icon {
  width: 176px;
  min-width: 176px;
  height: 167px;
  margin-right: 35px;
  border-radius: 50%;
}
@media (max-width: 767px) {
  .team__member-icon {
    width: 130px;
    min-width: 130px;
    height: 125px;
    margin-right: 25px;
  }
}
.team__member-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.team__member-info {
  text-transform: uppercase;
}
.team__member-name {
  margin-bottom: 8px;
}
.faq__spot {
  right: 0%;
  bottom: -20%;
  transform: rotate(75deg) translate(25%, -70%);
}
.faq__list {
  max-width: 1100px;
  min-width: 55vw;
  margin-top: 60px;
}
@media (max-width: 767px) {
  .faq__list {
    max-width: 80vw;
    margin: 0;
  }
}
.faq__item {
  padding: 24px 0;
  cursor: pointer;
}
.faq__item:hover .faq__item-name {
  color: #F97075;
}
.faq__item.--active .faq__item-name {
  color: #FF9800;
}
.faq__item.--active .faq__item-trigger {
  transform: rotate3d(0, 0, 1, 45deg);
}
.faq__item + .faq__item {
  border-top: 1px solid #F1F1F1;
}
.faq__item-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
}
.faq__item-name {
  margin-right: 15px;
  transition: all 0.25s;
}
.faq__item-trigger {
  position: relative;
  width: 34px;
  min-width: 34px;
  height: 34px;
  transition: all 0.25s;
}
.faq__item-trigger:before, .faq__item-trigger:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 2px;
  background-color: #8E8E8E;
}
.faq__item-trigger:before {
  transform: translate(-50%, -50%);
}
.faq__item-trigger:after {
  transform: translate(-50%, -50%) rotate(90deg);
}
.faq__item-body {
  max-height: 0;
  transition: all 0.25s;
  overflow: hidden;
}
.faq__item-content {
  padding-top: 25px;
}
.faq__item-info {
  max-width: 600px;
}
.faq__item-info + .faq__item-info {
  margin-top: 12px;
}
/*# sourceMappingURL=index.9194fe9d.css.map */
