.hero {
  position: relative;
  margin-top: 0;
  padding-top: 2vw;
  @media (max-width: 1024px) {
    padding-top: 8vw;
  }
  &__bg {
    position: absolute;
    top: 50px;
    width: 90vw;
    max-width: 1300px;
    z-index: 1;
    &-image {
      width: 100%;
    }
  }
  &__content {
    position: relative;
    text-align: center;
    z-index: 3;
  }
  &__decoration {
    position: absolute;
    &:first-child {
      top: -15%;
      left: -20%;
      transform: translateX(-100%);
      @media (max-width: 1024px) {
        left: -5%;
      }
    }
    &:nth-child(2) {
      top: -11%;
      right: -30%;
      transform: translateX(100%);
      @media (max-width: 1024px) {
        right: -11%;
      }
    }
    &:nth-child(3) {
      top: 78%;
      left: 5%;
      transform: translateX(-100%);
      @media (max-width: 1024px) {
        top: 78%;
        left: 5%;
      }
    }
    &:nth-child(4) {
      top: 57%;
      right: -18%;
      transform: translateX(100%);
      @media (max-width: 1024px) {
        right: 0;
      }
    }
    &:nth-child(5) {
      top: 125%;
      left: -25%;
      transform: translateX(-100%);
      @media (max-width: 1024px) {
        left: -5%;
      }
    }
    &:nth-child(6) {
      top: 109%;
      right: -22%;
      transform: translateX(100%);
      @media (max-width: 1024px) {
        right: -4%;
      }
    }
  }
  &__logos {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 4.5vw;
    z-index: 1;
    @media (max-width: 1024px) {
      margin-bottom: 8vw;
    }
  }
  &__logo {
    max-width: 15vw;
    &.--upcoming {
      width: 125px;
    }
    &.--calendar {
      width: 130px;
    }
    &.--r {
      width: 121px;
    }
    &.--non-fungi {
      width: 155px;
    }
    &:first-child,
    &:nth-child(3) {
      margin-right: 1.5vw;
    }
    &:nth-child(2) {
      margin-right: 3vw;
    }
    &-image {
      border-radius: 4px;
    }
  }
  &__title {
    & span {
      position: relative;
      & img {
        position: absolute;
        top: 0;
        right: 0;
        width: 55%;
        transform: translate(28%, -30%);
      }
    }
  }
  &__info {
    max-width: 500px;
    margin: 0 auto;
    @media (max-width: 1023px) {
      max-width: 450px;
    }
    @media (max-width: 767px) {
      max-width: 390px;
    }
    @media (max-width: 480px) {
      max-width: 350px;
    }
  }
  &__subtitle {
    margin-bottom: 12px;
  }
  &__extra {
    margin-top: 25px;
    &-item {
      margin: 0;
      & + & {
        margin-top: 12px;
      }
    }
  }
  &__button {
    position: relative;
    margin-top: 30px;
    &-cloud {
      position: absolute;
      top: 0;
      left: 0;
      width: 22.4%;
      transform: translate(-18%, -40%);
    }
    &-squares {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      &.--left {
        right: calc(100% + 23px);
        @media (max-width: 767px) {
          opacity: .15;
          right: 90%;
        }
      }
      &.--right {
        left: calc(100% + 23px);
        @media (max-width: 767px) {
          opacity: .15;
          left: 90%;
        }
      }
    }
  }
  &__socials {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  &__picture {
    position: relative;
    display: block;
    max-width: 70vw;
    margin-top: 30px;
    z-index: 2;
  }
}