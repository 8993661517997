* {
  &,
  &:before,
  &:after {
    box-sizing: border-box;
  }
  &:focus {
    outline: none;
  }
}

html {
  font-size: 16px;
  @media (max-width: 1023px) {
    font-size: 14px;
  }
  @media (max-width: 767px) {
    font-size: 12px;
  }
}

body {
  position: relative;
  margin: 0;
  color: $black;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0.1em;
  font-family: "DotGothic", Helvetica, Arial, sans-serif;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-focus-ring-color: rgba(255, 255, 255, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
}

ul, ol {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

address {
  font-style: normal;
}

img,
video,
iframe {
  max-width: 100%;
}

img {
  border: 0;
  vertical-align: top;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

input,
textarea {
  width: 100%;
  padding: 0;
  border: none;
  font-family: "DotGothic", Helvetica, Arial, sans-serif;
  background-color: transparent;
}

textarea {
  height: 110px;
  resize: none;
}

button {
  font-family: "DotGothic", Helvetica, Arial, sans-serif;
  text-align: center;
  border: none;
  background: none;
  cursor: pointer;
}

.wrapper {
  padding: 0 30px;
  overflow: hidden;
  @media (max-width: 767px) {
    padding: 0 15px;
  }
}

.section {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 11vw 0;
  @media (max-width: 480px) {
    margin: 25vw 0;
  }
  &.--wide {
    margin: 0 -30px;
    @media (max-width: 767px) {
      margin: 0 -15px;
    }
  }
}

.title {
  margin-bottom: 25px;
  font-size: 5.5625rem;
  text-transform: uppercase;
  @media (max-width: 767px) {
    font-size: 5rem;
  }
  @media (max-width: 480px) {
    font-size: 4rem;
  }
  &.--section {
    font-size: 2.375rem;
    line-height: 1.5;
    text-align: center;
  }
  &.--block {
    font-size: 1.875rem;
  }
  &.--extra {
    font-size: 1.5rem;
  }
  &.--subtitle {
    font-size: 1.375rem;
  }
}

.text {
  font-size: 0.875rem;
  line-height: 1.5;
  &.--lead {
    font-size: 1.125rem;
  }
  &.--head {
    font-size: 1.25rem;
  }
  &.--date {
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.2;
  }
  &.--normal {
    font-weight: 400;
  }
}

.button {
  display: inline-block;
  min-width: 236px;
  padding: 16px;
  color: $white;
  font-size: 1.125rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  border-radius: 4px;
  background-color: $black;
  transition: all .25s;
  @media (max-width: 480px) {
    min-width: 200px;
  }
  &:hover {
    box-shadow: 0px 10px 40px 0px rgba(63, 126, 202, 0.25);
  }
}

.list {
  & > * {
    display: flex;
    align-items: center;
    &:before {
      content: url("./images/dot.svg");
      display: block;
      width: 24px;
      height: 24px;
      margin-right: 14px;
    }
  }
}

.accent {
  padding: 2px 5px;
  background-color: $text_yellow;
}

.none {
  display: none;
}

.link {
  display: inline;
  padding-bottom: 1px;
  color: $blue;
  line-height: 1.3;
  border-color: unset;
  background-image: linear-gradient(currentColor, currentColor);
  background-size: 0% 2px;
  background-repeat: no-repeat;
  background-position: left bottom;
  transition: all .25s;
  cursor: pointer;
  &:hover {
    background-size: 100% 2px;
  }
}

.spot,
.square {
  position: absolute;
  max-width: 25vw;
}

.square {
  @media (max-width: 1023px) {
    opacity: .15;
  }
}
