.team {
  display: flex;
  align-items: center;
  justify-content: center;
  &__spot {
    bottom: -40%;
    left: 0%;
    transform: rotate(110deg) translate(-50%, 85%);
  }
  &__content {
    display: flex;
    align-items: center;
    margin-top: 60px;
    @media (max-width: 767px) {
      flex-direction: column-reverse;
      margin-top: 20px;
    }
  }
  &__info {
    width: 475px;
    max-width: 35vw;
    margin-right: 12vw;
    text-transform: uppercase;
    @media (max-width: 1023px) {
      margin-right: 6vw;
    }
    @media (max-width: 767px) {
      max-width: 80vw;
    }
  }
  &__socials {
    margin-top: 25px;
  }
  &__picture {
    position: relative;
    display: flex;
    justify-content: center;
    width: 348px;
    max-width: 30vw;
    @media (max-width: 767px) {
      max-width: 50vw;
      margin-bottom: 40px;
    }
    &-square {
      &:first-of-type {
        right: calc(100% + 30px);
        bottom: 0;
      }
      &:nth-of-type(2) {
        top: 0;
        left: calc(100% + 30px);
      }
    }
    &-lines {
      position: absolute;
      max-width: 60vw;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      @media (max-width: 1023px) {
        opacity: .2;
      }
      @media (max-width: 767px) {
        max-width: 95vw;
      }
    }
    &-block {
      position: relative;
      border-radius: 145px;
      background-color: $bg_gray;
      box-shadow: $shadow;
      overflow: hidden;
      z-index: 2;
      
    }
  }
  &__hey {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    &-icon {
      margin-left: 10px;
    }
  }
  &__description {
    &:not(:first-child) {
      margin-top: 18px;
    }
  }
  &__list {
    margin-top: 15px;
    &-item {
      & + & {
        margin-top: 14px;
      }
    }
  }
  &__members {
    display: flex;
    justify-content: center;
    margin-top: 80px;
    @media (max-width: 1023px) {
      flex-direction: column;
    }
    @media (max-width: 767px) {
      margin-top: 40px;
    }
  }
  &__member {
    display: flex;
    align-items: center;
    width: 520px;
    max-width: 45vw;
    @media (max-width: 1023px) {
      max-width: 80vw;
      & + & {
        margin-top: 50px;
      }
    }
    &:not(:last-of-type) {
      margin-right: 50px;
      @media (max-width: 1023px) {
        margin-right: unset;
      }
    }
    &-icon {
      width: 176px;
      min-width: 176px;
      height: 167px;
      margin-right: 35px;
      border-radius: 50%;
      @media (max-width: 767px) {
        width: 130px;
        min-width: 130px;
        height: 125px;
        margin-right: 25px;
      }
    }
    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-info {
      text-transform: uppercase;
    }
    &-name {
      margin-bottom: 8px;
    }
  }
}